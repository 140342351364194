import {
  RawPrompt,
  RawSpaceDTO,
  SpaceConfigInterface,
  SpaceDTO,
  SpaceProvider,
  SupportedSpaces,
} from '../types/spaceConfig';
import { ApiService } from './apiService';
import { features } from '../../global/features';
import spacesTestData from '../../test-data/spaces.json';
import { MidasService } from './midasService';
import { midasSpaces } from '../../global/midas-spaces';
import { PUBLIC_SPACE } from '../hooks/spaces';
import { LLMId } from '../types/llm';
import { StageService } from './stageService';
import i18nConfig from '../../i18n.config.json';
import { TranslatedString } from '../types/translation';
import { HaloSpaceDataDTO } from '../types/halo';

export class SpaceAdministrationService {
  // we only enable llms in this list, all others are being flagged as 'coming soon' even if the backend reports them as enabled
  static publicSpaceLlmWhitelist: LLMId[] =
    StageService.isProd || StageService.isQa
      ? ['GPT3', 'GPT4', 'GEMINI', 'PALM2']
      : StageService.isBeta || StageService.isAlpha
        ? ['GPT3']
        : ['GPT3', 'GPT4', 'GEMINI', 'PALM2'];

  static buildUri(path: string): string {
    if (process.env.OVERRIDE_SPACE_ADMINISTRATION_URI) {
      return `${process.env.OVERRIDE_SPACE_ADMINISTRATION_URI}${path}`;
    }
    return `${process.env.GATSBY_API_BASE_URI!}${process.env.GATSBY_SPACE_ADMINISTRATION_SUB_URI!}${path}`;
  }

  static async getSpaces(lang: string, token: string, provider?: SpaceProvider): Promise<SpaceConfigInterface[]> {
    const spacesData: SupportedSpaces = provider
      ? await ApiService.request<SupportedSpaces>(this.buildUri('/spaces/' + provider), token)
      : features.useTestData
        ? (spacesTestData as SupportedSpaces)
        : MidasService.midasMode
          ? (midasSpaces as unknown as SupportedSpaces) // TODO: fix type
          : await ApiService.request<SupportedSpaces>(this.buildUri('/spaces'), token);

    if (!features.useTestData && !MidasService.midasMode) {
      spacesData.spaces.forEach((space: any) => {
        if (space.id === PUBLIC_SPACE) {
          space.config?.llms.forEach((llm: any) => {
            llm.enabled = this.publicSpaceLlmWhitelist.includes(llm.id);
          });
        }
      });
    }

    return spacesData.spaces.map(space => this.mapSpaceConfig(space, lang));
  }

  static async getSpaceDetails(lang: string, spaceId: string, token: string): Promise<SpaceDTO> {
    return this.mapSpaceConfig(
      await ApiService.request<RawSpaceDTO>(this.buildUri(`/spaces/detail/${spaceId}`), token),
      lang,
    );
  }

  static mapSpaceConfig(space: RawSpaceDTO, lang: string): SpaceDTO {
    return {
      ...space,
      ...(Object.fromEntries(
        Object.entries(space)
          .filter(([key]) => ['name', 'shortDescription', 'description'].includes(key))
          .map(([key, value]) => [key, value[lang] ?? value[i18nConfig.defaultLanguage]]),
      ) as Pick<SpaceDTO, 'name' | 'shortDescription' | 'description'>),
      keywords: space.keywords?.map(
        (keyword: TranslatedString) => keyword[lang] ?? keyword[i18nConfig.defaultLanguage],
      ),
      samplePrompts: space.samplePrompts?.map((prompt: RawPrompt) => ({
        title: prompt.title[lang] ?? prompt.title[i18nConfig.defaultLanguage],
        prompt: prompt.prompt[lang] ?? prompt.prompt[i18nConfig.defaultLanguage],
      })),
    } as SpaceDTO;
  }

  static createDraftSpace(token: string, haloData: HaloSpaceDataDTO) {
    return ApiService.request(this.buildUri('/drafts'), token, {
      method: 'POST',
      json: { draftType: 'HALO', haloData },
    });
  }

  static deleteDraftSpace(token: string, spaceId: string) {
    return ApiService.request(this.buildUri(`/drafts/${spaceId}`), token, {
      method: 'DELETE',
      raw: true,
    });
  }
}
